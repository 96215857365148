import { h } from 'preact';

const LinkedInIcon = () => (

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 4.27673C4 3.01933 5.01933 2 6.27673 2C7.53459 2 8.55346 3.01887 8.55346 4.27673C8.55346 5.53413 7.53413 6.55346 6.27673 6.55346C5.01887 6.55346 4 5.53459 4 4.27673Z" fill="currentColor" />
<path d="M22 20H18.1429V13.3333C18.1429 12.2912 17.2081 11.4465 16.1661 11.4465C15.124 11.4465 14.2793 12.2912 14.2793 13.3333V20H10.4286V8.42853H14.2793V10.1886C14.9082 9.13203 16.2793 8.42763 17.424 8.42763C19.8554 8.42763 22 10.3987 22 12.8301V20Z" fill="currentColor" />
<path d="M4 20H7.85714V8.42857H4V20Z" fill="currentColor" />
</svg>


);

export default LinkedInIcon;