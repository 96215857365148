import { h } from 'preact';
import register from 'preact-custom-element';
import DiscordIcon from './discordIcon'
import FacebookIcon from './facebookIcon'
import TwitterIcon from './twitterIcon'
import LinkedInIcon from './linkedInIcon'
import InstagramIcon from './instagramIcon'

const Footer = ({authenticated = "false"}) => {

  const breakpoints = {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  }

  return (
    <div class="wc-footer">
      <style dangerouslySetInnerHTML={{__html: `
        .wc-footer {
          font-size: 14px;
          padding: 24px 0;
          background: #050035;
        }
            @media (min-width: ${breakpoints.xl}) {
              .wc-footer {
                padding: 16px 0; 
              }
            }
        .wc-footer--container {
          width: 100%;
          max-width: 1440px;
          margin: 0 auto;
          padding: 0 16px;
          box-sizing: border-box;
        }
        .wc-footer--innerContainer {
          display: flex;
          justify-content: space-between;
          gap: 12px;
          color: #9C9AB6;
          flex-direction: column;
        }
            @media (min-width: ${breakpoints.xl}) {
              .wc-footer--innerContainer {
                flex-direction: row;
                order: 2;
              }
            }
        .wc-footer--innerContainer a {
          color: #9C9AB6;
          text-decoration: none;
        }
        .wc-footer--innerContainer a:hover,
        .wc-footer--innerContainer a:focus, 
        .wc-footer--innerContainer a:active {
          color: #FFF;
          text-decoration: underline;
        }
        .wc-footer--siteLinks {
          display: flex;
          gap: 12px;
          order: 2;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
        }
            @media (min-width: ${breakpoints.xl}) {
              .wc-footer--siteLinks {
                order: 1;
              }
            }
            @media (min-width: ${breakpoints.md}) {
              .wc-footer--siteLinks {
                flex-direction: row;
                align-items: center;
              }
            }
        .wc-footer--siteLinksCopyright {
          text-align: center;
        }
            @media (min-width: ${breakpoints.md}) {
              .wc-footer--siteLinksCopyright {
                text-align: left;
              }
            }
        .wc-footer--siteLinksRow {
          display: flex;
          gap: 12px;
        }
        .wc-footer--siteLinksColumn {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 50%;
        }
        .wc-footer--siteLinksColumn:nth-child(2) {
          text-align: right;
        }
            @media (min-width: ${breakpoints.md}) {
              .wc-footer--siteLinksColumn {
                flex-direction: row;
                width: auto;
              }
            }

        .wc-footer--socialLinks {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          order: 1;
        }
            @media (min-width: ${breakpoints.xl}) {
              .wc-footer--socialLinks {
                flex-direction: row;
                order: 2;
              }
            }
        .wc-footer--socialLinksMobile {
          display: flex;
          gap: 12px;
        }
        .wc-footer--socialLinksNetworks {
          display: flex;
          gap: 8px;
        }
      `}} />
      <div class="wc-footer--container">
        <div class="wc-footer--innerContainer">
          
          <div class="wc-footer--siteLinks">
            <div class="wc-footer--siteLinksCopyright">© {new Date().getFullYear()} A Cloud Guru</div>
            <div class="wc-footer--siteLinksRow">
              <div class="wc-footer--siteLinksColumn">
                <a target="_blank" rel="noopener noreferrer" class="chakra-link css-f4h6uy" href="https://acloud.guru/code-of-conduct">Code of Conduct</a>
                <a target="_blank" rel="noopener noreferrer" class="chakra-link css-f4h6uy" href="https://acloud.guru/privacy">Privacy Policy</a>
                <a target="_blank" rel="noopener noreferrer" class="chakra-link css-f4h6uy" href="https://acloud.guru/terms-of-use">Terms of Use</a>
              </div>
              <div class="wc-footer--siteLinksColumn">
                <a target="_blank" rel="noopener noreferrer" class="chakra-link css-f4h6uy" href="https://acloudguru.com/careers">Careers</a>
                <a target="_blank" rel="noopener noreferrer" class="chakra-link css-f4h6uy" href="https://acloudguru.com/blog">Blog</a>
                {authenticated === "true" && <a target="_blank" rel="noopener noreferrer" class="chakra-link css-f4h6uy" href="https://acloud.guru/settings/account">Manage my account</a>}
                <a target="_blank" rel="noopener noreferrer" class="chakra-link css-f4h6uy" href="https://help.acloud.guru/">Support</a>
              </div>
            </div>
          </div>

          <div class="wc-footer--socialLinks">

            <div class="wc-footer--socialLinksMobile">
              <a target="_blank" rel="noopener noreferrer" class="chakra-link css-f4h6uy" href="https://play.google.com/store/apps/details?id=com.acloudguru" data-acg-focus="true" aria-label="Download app from Google Play store">
                <img src="https://res.cloudinary.com/acloud-guru/image/fetch/h_36,f_auto/https://acloudguru-content-assets-production.s3-accelerate.amazonaws.com/1661735530216-app-store-android.png" width="123" height="36" alt="" loading="lazy" />
              </a>
              <a target="_blank" rel="noopener noreferrer" class="chakra-link css-f4h6uy" href="https://apps.apple.com/au/app/a-cloud-guru/id1286720016" data-acg-focus="true" aria-label="Download app from Apple's app store">
                <img src="https://res.cloudinary.com/acloud-guru/image/fetch/h_36,f_auto/https://acloudguru-content-assets-production.s3-accelerate.amazonaws.com/1661735556743-app-store-ios.png" width="108" height="36" alt="" loading="lazy" />
              </a>
            </div>

            <div class="wc-footer--socialLinksNetworks">
              <a href="#"><DiscordIcon /></a>
              <a href="#"><TwitterIcon /></a>
              <a href="#"><FacebookIcon /></a>
              <a href="#"><InstagramIcon /></a>
              <a href="#"><LinkedInIcon /></a>
            </div>

          </div>
      </div>{/* /.wc-footer--innerContainer */}
    </div>{/* /.wc-footer--container */}
  </div>
  );
};
    
register(Footer, 'wc-footer', ['authenticated'], {shadow: true});

export default Footer;
      