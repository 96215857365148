import { h } from 'preact';

const DiscordIcon = () => (

<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2654_7421)">
<path d="M20.3303 4.55593C18.7767 3.82906 17.1156 3.3008 15.3789 3C15.1656 3.38561 14.9164 3.90427 14.7446 4.31685C12.8985 4.03922 11.0693 4.03922 9.25716 4.31685C9.08539 3.90427 8.83055 3.38561 8.61536 3C6.87681 3.3008 5.21376 3.831 3.66019 4.55978C0.526643 9.29499 -0.322811 13.9126 0.101917 18.4646C2.18025 20.0166 4.19441 20.9595 6.17457 21.5764C6.66349 20.9035 7.09953 20.1883 7.47518 19.4344C6.75975 19.1626 6.07453 18.8271 5.42707 18.4376C5.59884 18.3104 5.76686 18.1773 5.92918 18.0404C9.87819 19.8875 14.1689 19.8875 18.0707 18.0404C18.235 18.1773 18.403 18.3104 18.5728 18.4376C17.9235 18.829 17.2364 19.1645 16.521 19.4363C16.8966 20.1883 17.3308 20.9055 17.8216 21.5783C19.8036 20.9614 21.8197 20.0186 23.898 18.4646C24.3964 13.1876 23.0467 8.61246 20.3303 4.55593ZM8.01318 15.6651C6.82772 15.6651 5.85555 14.5585 5.85555 13.2108C5.85555 11.8631 6.80696 10.7545 8.01318 10.7545C9.21942 10.7545 10.1916 11.8612 10.1708 13.2108C10.1727 14.5585 9.21942 15.6651 8.01318 15.6651ZM15.9867 15.6651C14.8013 15.6651 13.8291 14.5585 13.8291 13.2108C13.8291 11.8631 14.7805 10.7545 15.9867 10.7545C17.193 10.7545 18.1651 11.8612 18.1444 13.2108C18.1444 14.5585 17.193 15.6651 15.9867 15.6651Z" fill="currentColor" />
</g>
<defs>
<clipPath id="clip0_2654_7421">
<rect width="24" height="24" fill="white" />
</clipPath>
</defs>
</svg>

);

export default DiscordIcon;